<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="9">
            <b-row>
              <b-col>
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.accessible-zone-label') }}
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="background-light-blue-inputs tags-container">
                   <span
                       class="custom-tag tag-light-blue ml-1 mt-1 mr-1"
                   >
                      Fitness
                       <button
                           type="button" class="close"
                           data-dismiss="alert"
                           aria-label="Close"
                       >
                         <span aria-hidden="true">&times;</span>
                       </button>
               </span>
                  <span
                      class="custom-tag tag-light-blue ml-1 mt-1 mr-1"
                  >
                      Cardio
                       <button
                           type="button" class="close"
                           data-dismiss="alert"
                           aria-label="Close"
                       >
                         <span aria-hidden="true">&times;</span>
                       </button>
               </span>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="index === 0" class="description-gray pr-0" cols="3">
            {{ $t('components.form.subscriptions.new.descriptions.description-accessible-zone') }}
          </b-col>
        </b-row>
        <b-row class="pt-0 pb-0 mt-1 mb-0" v-if="index !== 0">
          <b-col cols="9"/>
          <b-col align="right" cols="3" class="mt-2">
            <remove-rate
                text-link="components.form.subscriptions.new.remove-new-rate"
                @on:remove-link-click="$emit('on:control-access-remove-click', index)"
            />
          </b-col>
        </b-row>
        <b-row :class="index === 0 ? 'mt-3' : 'mt-0'">
          <b-col cols="4">
            <b-row>
              <b-col>
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.nb-recurrence') }}
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <d-text-field
                    size="sm"
                    place-holder="0"
                    :class-name="'background-light-blue-inputs'"
                    error-text="validation.text-input-three-characters"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="5">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.access-recurrence') }}
                </label>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="4">
                    <b-form-input
                        size="sm"
                        class="background-light-blue-inputs"
                        placeholder="0"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col>
                    <select size="sm" class="form-control background-light-blue-inputs">
                      <option :value="true">{{ $t('general.actions.days') }}</option>
                      <option :value="false">{{ $t('general.actions.months') }}</option>
                      <option :value="false">{{ $t('general.actions.year') }}</option>
                    </select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="index === 0" class="description-gray pr-0" cols="3">
            {{ $t('components.form.subscriptions.new.descriptions.description-access-autorisation') }}
          </b-col>
        </b-row>
        <div class="mt-3 border-bottom-grey"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  components: {
    RemoveRate: () => import('@custom/subscrtiptions/new/RemoveItemLink')
  },
  props: {
    index: {
      type: Number,
      default: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
/deep/ .input-group-text {
  line-height: 0;
}

.custom-padding {
  padding-left: 18px;
}

.custom-tag {
  padding: 0.43em 0.4em;
  line-height: 1;
}

.custom-tag .close {
  margin-left: .20rem;
  font-size: 13px;
  color: #276772;
}

.custom-tag .close:hover {
  color: #092b30;
}
.tags-container{
  min-height: 100px; border-radius: 4px;border: 1px solid #ced4da;
}
</style>
